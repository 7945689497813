import React from 'react';
// import { graphql } from "gatsby";
import { Helmet } from 'react-helmet';

import PageWrapper from '../components/PageWrapper';
import ExchangeSMS from '../sections/home/ExchangeSMS';
import Hero from '../sections/home/Hero';
import Progress from '../sections/home/Progress';
// import Blog from "../sections/home/Blog";
import WaysToChat from '../sections/home/WaysToChat';
// import BestMentors from "../sections/home/BestMentors";
// import Testimonial from "../sections/home/Testimonial";
import HeaderButton from '../components/HeaderButton';
import CallToAction from '../sections/home/CTA';

const Home = ({ data }) => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-right',
          headerButton: <HeaderButton />,
          footerStyle: 'style3',
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>UStrive | Online Mentoring</title>
          <link rel="canonical" href="https://ustrive.com" />
          <meta
            name="facebook-domain-verification"
            content="931s0luslv0yw827a9l1b43iqmlktb"
          />
          <meta
            name="description"
            content="We connect high school and college students with mentors from top companies."
          />
        </Helmet>
        <Hero className="pt-11 pt-lg-34 pb-8" />
        {/* <Company className="pt-12 pb-9" /> */}
        <Progress className="pb-lg-21 border-top border-default-color-1" />
        <ExchangeSMS className="bg-default-6 pt-14 pt-md-19 pt-lg-30 pb-9 pb-md-14 pb-lg-34 overflow-hidden position-relative" />
        {/* <BestMentors className="pb-12 pb-lg-25 pt-14 pt-md-19 pt-lg-20" /> */}
        {/* <Blog
          posts={data.allContentfulBlogPost}
          className="bg-default-1 pt-14 pt-md-18 pt-lg-27 pb-13 pb-md-17 pb-lg-26"
        /> */}
        <WaysToChat className="pb-0 pb-lg-20 pt-14 pt-md-19 pt-lg-20 bg-default-2" />
        {/* <Testimonial className="pt-lg-19 pb-12 pb-lg-17" /> */}
        <CallToAction className="bg-images" />
      </PageWrapper>
    </>
  );
};

export default Home;
