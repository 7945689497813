import React, { useContext } from 'react';
import backgroundImage from '../../assets/image/home-2/jpg/promo-bg-img.jpg';
import GlobalContext from '../../context/GlobalContext';

const Content3 = ({ className, ...rest }) => {
  const gContext = useContext(GlobalContext);
  return (
    <>
      <div
        className={className}
        {...rest}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div
                className="text-center pt-14 pb-15 py-lg-31 dark-mode-texts"
                data-aos="zoom-in"
                data-aos-delay="250"
              >
                <h2 className="font-size-11 mb-7">
                  It only takes a few minutes.
                </h2>
                {/* <button
                  onClick={() => gContext.setVisitorPopupVisible(true)}
                  className="btn btn btn-dodger-blue-2 header-btn rounded-5"
                >
                  Get started today
                </button> */}
                <button
                  onClick={() =>
                    (window.location.href =
                      'https://app.ustrive.com/students/student')
                  }
                  className="btn btn btn-dodger-blue-2 header-btn rounded-5"
                >
                  Get started today
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content3;
