import React, { useContext } from 'react';
import { registerURL } from '../../utils';

import imgBP from '../../assets/image/home-2/png/dot-pattern-blue.png';
import imgSBR from '../../assets/image/home-2/png/round-shape-blue.png';
import imgH from '../../assets/image/top-mentor.jpg';
import imgH2 from '../../assets/image/top-student.jpg';
import GlobalContext from '../../context/GlobalContext';

const Hero = ({ className, ...rest }) => {
  const gContext = useContext(GlobalContext);
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row justify-content-center">
            {/* <!-- Hero Content --> */}
            <div
              className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1"
              data-aos="fade-right"
              data-aos-delay="500"
            >
              <div className="pr-lg-10 pr-xl-0 pt-0 pt-lg-0 pb-13 pb-lg-0">
                <h1 className="font-size-12 mb-8">Free Online Mentoring.</h1>
                <p className="font-size-7 mb-0 heading-default-color">
                  We connect individuals with mentors to help
                  <br className="d-none d-md-block" /> them achieve their
                  college and career dreams!
                </p>
                <div className="pt-5 pt-lg-10">
                  <button
                    onClick={() =>
                      (window.location.href =
                        'https://app.ustrive.com/students/student')
                    }
                    className="btn btn-dodger-blue-2 rounded-5"
                  >
                    Find a Mentor
                  </button>
                  <span className="d-block pt-5 pt-lg-9 heading-default-color">
                    Looking to mentor?{' '}
                    <a
                      href="#"
                      onClick={() =>
                        (window.location.href =
                          'https://app.ustrive.com/mentors/mentor')
                      }
                    >
                      Click here
                    </a>
                  </span>
                </div>
              </div>
            </div>
            {/* <!-- End Hero Content --> */}
            {/* <!-- l2-hero-image-group --> */}
            <div
              className="col-xl-7 col-lg-6 col-md-7 order-1 order-lg-2"
              data-aos="fade-left"
              data-aos-delay="800"
            >
              <div className="l2-hero-image-group mt-5 mt-lg-0">
                <div className="img-1">
                  <img src={imgSBR} alt="" />
                </div>
                <div className="img-2">
                  <img src={imgBP} alt="" />
                </div>
                <div className="img-3">
                  <img src={imgH} alt="" />
                </div>
                <div className="img-4">
                  <img src={imgH2} alt="" />
                </div>
                {/* <!-- Image Group Content --> */}
                <div className="img-group-content bg-white absolute-center shadow-4 max-w-193 d-none d-md-block">
                  <div className="seciton-body">
                    <div className="mb-7">
                      <h5 className="font-size-6 font-weight-medium mb-0 line-height-reset text-dark-cloud">
                        Nathaniel D.
                      </h5>
                      <p className="font-size-3 text-stone">
                        New York University
                      </p>
                    </div>
                    <h6 className="font-size-3 text-stone">About Nathaniel:</h6>
                    <p className="font-size-3 text-dark-cloud">
                      I've been a software developer for nearly 10 years and I
                      am...
                    </p>
                  </div>
                  <div className="d-flex align-items-center w-100">
                    <a
                      href={registerURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-buttercup w-50 text-white"
                    >
                      <i className="fa fa-arrow-left font-size-3 mr-1"></i>
                      Browse
                    </a>
                    <a
                      href={registerURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-primary w-50"
                    >
                      <i className="fa fa-check font-size-3 mr-1"></i> Select
                    </a>
                  </div>
                </div>
                {/* <!-- End Image Group Content --> */}
              </div>
            </div>
            {/* <!-- End l2-hero-image-group --> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
